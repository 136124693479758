<template>
  <header class="bg-white">
    <nav class="flex items-center justify-between py-2 px-12 md:shadow-md md:rounded-b-2xl h-24" aria-label="Global">
      <div class="flex lg:flex-1">
        <h2 class="text-xl font-semibold flex-auto">
          <router-link to="/">{{ $t("students.exams.title") }}</router-link>
        </h2>
      </div>
      <div class="flex gap-x-6 justify-end items-center">
        <div class="relative" v-if="isAuthenticated">
          <button @click="toggleUserDropdown" class="text-sm font-semibold leading-6 text-gray-900 flex items-center" :class="{ 'text-indigo-600': showUserDropdown || $route.path.includes('/settings') }">
            <div class="flex items-center rounded-full border-[2px] border-indigo-100 gap-x-4 pe-4">
              <img src="@/assets/images/avatar.png" class="w-8 h-8" />
              <p class="text-sm text-gray-700">{{ teacherProfile.name }}</p>
            </div>
          </button>
          <div v-show="showUserDropdown" class="absolute end-0 mt-2 w-48 bg-white rounded-lg shadow-2xl z-20 border">
            <ButtonConfirmation @confirm="logoutUser" buttonClass="w-full text-start px-4 py-4 text-sm text-red-500 hover:bg-gray-100">
              {{ $t("user.logout.title") }}
            </ButtonConfirmation>
          </div>
        </div>
        <LanguageSwitcher :initialLanguage="teacherProfile.preferred_language" v-show="false" v-if="isAuthenticated" />
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useCurrentUser } from "@/composables/auth/useCurrentUser";

const showUserDropdown = ref(false);
const { isAuthenticated, logoutUser, teacherProfile } = useCurrentUser();

const toggleUserDropdown = () => {
  showUserDropdown.value = !showUserDropdown.value;
};
</script>
