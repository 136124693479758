import { Module } from 'vuex';
import { User } from '@/interfaces/User';
import { Teacher } from '@/interfaces/Teacher';

interface CurrentUserState {
    currentUser: User | null;
    teacherProfile: Teacher | null;
}

const currentUserState: Module<CurrentUserState, any> = {
    namespaced: true,
    state: {
        currentUser: null,
        teacherProfile: null
    },
    mutations: {
        setCurrentUser(state, user: User | null) {
            state.currentUser = user;
        },
        setTeacherProfile(state, teacher: Teacher | null) {
            state.teacherProfile = teacher;
        },
    },
    actions: {
        updateUser({ commit }, user: User | null) {
            commit('setCurrentUser', user);
        },
        updateTeacherProfile({ commit }, teacher: Teacher | null) {
            commit('setTeacherProfile', teacher);
        },
    },
    getters: {
        currentUser: (state) => state.currentUser,
        teacherProfile: (state) => state.teacherProfile,
        isAuthenticated: (state) => !!state.currentUser,
        /**
         * Check if the user has a specific permission
         */
        can: (state) => (permission: string) => {
            return state.currentUser?.permissions.some(p => p.permission === permission) || false;
        },
        /**
         * Check if the user has a specific role
         */
        hasRole: (state) => (role: string) => {
            return state.currentUser?.roles.some(r => r.role === role) || false;
        }
    }
};

export default currentUserState;
