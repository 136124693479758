import { Teacher } from '@/interfaces/Teacher';
import apiClient from './apiService'
import { Exam, ExamQueryParams } from '@/interfaces/Exam'

export default {

    // Teacher Profile.
    async getTeacherProfile(userId: number): Promise<ApiResponse<Teacher>> {
        const response = await apiClient.get<ApiResponse<Teacher>>(`/teachers/${userId}/profile`)
        return response.data;
    },

    // Teacher Mock Exams.
    async getTeacherMockExams(teacherId: number, params?: ExamQueryParams): Promise<ApiResponse<Exam[]>> {
        const response = await apiClient.get<ApiResponse<Exam[]>>(`/teachers/${teacherId}/exams/mock`, { params })
        return response.data;
    },

    async previewExam(teacherId: number, examId: number): Promise<ApiResponse<Exam>> {
        const response = await apiClient.get<ApiResponse<Exam>>(`/teachers/${teacherId}/${examId}/preview`)
        return response.data;
    },

}