<template>
  <div>
    <p class="text-gray-500 text-lg">{{ message }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

interface Props {
  message: string;
}

const props: Props = defineProps({
  message: {
    type: String,
    required: true,
  },
});

const message = ref(props.message);
</script>

<style scoped>
/* Add your scoped styles here */
</style>
