<template>
  <div class="h-screen flex items-center justify-center p-4 bg-gray-100">
    <div class="text-center">
      <h1 class="text-2xl font-bold mb-4">{{ $t("desktop.title") }}</h1>
      <p class="text-md leading-loose">{{ $t("desktop.message") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
