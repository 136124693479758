<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" @click.self="emit('cancel')">
    <div class="bg-white p-10 rounded-lg shadow-lg w-full max-w-2xl" :class="{ 'ring-4 ring-red-600': isCritical, 'ring-4 ring-yellow-300': isWarning }">
      <div class="flex gap-x-4">
        <img v-if="isWarning" src="@/assets/icons/warn.svg" class="w-16 h-16" />
        <img v-if="isCritical" src="@/assets/icons/critical.svg" class="w-16 h-16" />
        <div class="flex-1">
          <h2 class="text-2xl font-semibold mb-4">{{ title ? title : $t("common.confirm_action") }}</h2>
          <p class="text-xl mb-6">{{ message ? message : $t("common.confirm_action_message") }}</p>
        </div>
      </div>
      <div class="flex justify-end gap-x-2">
        <button @click="emit('cancel')" type="button" class="me-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200">
          {{ cancelText ? cancelText : $t("common.cancel") }}
        </button>
        <button @click="emit('confirm')" class="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600" :class="{ 'bg-red-500': isCritical, 'bg-yellow-300 text-black hover:bg-yellow-500': isWarning }">
          {{ confirmText ? confirmText : $t("common.confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Props
defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
  message: {
    type: String,
    default: null,
  },
  cancelText: {
    type: String,
    default: null,
  },
  confirmText: {
    type: String,
    default: null,
  },
  cancelButtonClass: {
    type: String,
    default: "me-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200",
  },
  confirmButtonClass: {
    type: String,
    default: "px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600",
  },
  isWarning: {
    type: Boolean,
    default: false,
  },
  isCritical: {
    type: Boolean,
    default: false,
  },
});

// Emits
const emit = defineEmits(["cancel", "confirm"]);
</script>

<style scoped>
/* Add any necessary styles */
</style>
