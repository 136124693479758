import { App } from 'vue';
import ButtonConfirmation from './components/common/ButtonConfirmation.vue';
import ConfirmationDialog from './components/common/ConfirmationDialog.vue';
import ConnectionDialog from './components/common/ConnectionDialog.vue';
import Spinner from './components/common/Spinner.vue';
import MessageLayout from './components/common/MessageLayout.vue';
import AudioPlayer from './components/common/AudioPlayer.vue';
import FilesUploader from './components/common/FilesUploader.vue';
import LanguageSwitcher from './components/common/LanguageSwitcher.vue';

import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Import the i18n instance
import { i18n } from './i18n';

export default {
  install(app: App) {
    app.component('ButtonConfirmation', ButtonConfirmation);
    app.component('ConfirmationDialog', ConfirmationDialog);
    app.component('Spinner', Spinner);
    app.component('MessageLayout', MessageLayout);
    app.component('AudioPlayer', AudioPlayer);
    app.component('ConnectionDialog', ConnectionDialog);
    app.component('FilesUploader', FilesUploader);
    app.component('LanguageSwitcher', LanguageSwitcher);

    const currentLocale = i18n.global.locale.value;
    const isRtl = currentLocale === 'ar';

    const toastOptions: PluginOptions = {
      position: POSITION.BOTTOM_CENTER,
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: isRtl,
    };

    app.use(Toast, toastOptions);
  }
};