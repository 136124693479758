<template>
  <div>
    <select
      id="language_switcher_id"
      @change="changeLanguage"
      v-model="currentLanguage"
      class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      <option value="en">English</option>
      <option value="ar">العربية</option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  initialLanguage: {
    type: String,
    default: null,
  },
});

const { locale } = useI18n();
const currentLanguage = ref(locale.value);

// Set initial language
onMounted(() => {
  if (props.initialLanguage) {
    currentLanguage.value = props.initialLanguage;
    locale.value = props.initialLanguage;
    localStorage.setItem("language", props.initialLanguage);
  } else {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      currentLanguage.value = savedLanguage;
      locale.value = savedLanguage;
    }
  }
  updateDirection(currentLanguage.value);
});

const changeLanguage = (event: any) => {
  const selectedLanguage = event.target.value;
  locale.value = selectedLanguage;
  currentLanguage.value = selectedLanguage;
  localStorage.setItem("language", selectedLanguage);
  updateDirection(selectedLanguage);
};

const updateDirection = (language: string) => {
  const direction = language === "ar" ? "rtl" : "ltr";
  document.documentElement.setAttribute("dir", direction);
};

// Watch for changes in the locale to update direction dynamically
watch(currentLanguage, (newLocale) => {
  updateDirection(newLocale);
});
</script>
