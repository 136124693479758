import axios, { AxiosInstance, AxiosError } from 'axios'
import store from '@/store';
import config from '@/config'
import authService from './authService'

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

interface ErrorResponse {
    message?: string;
    errors?: Record<string, string[]>;
    status?: string;
}

const apiClient: AxiosInstance = axios.create({
    baseURL: config.BACKEND_URL || 'http://localhost:8000/api',
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    timeout: 10000,
})

// Request interceptor
apiClient.interceptors.request.use(async config => {

    const selectedLanguage = localStorage.getItem('language')
    if (selectedLanguage) { config.headers['Accept-Language'] = selectedLanguage }
    
    const fullUrl = (config.baseURL || '') + (config.url || '');
    if (fullUrl.includes('/login')) { return config; }

    // await delay(250)  // Delay simulation
    return config
})

// Response interceptor
apiClient.interceptors.response.use(
    response => response,
    async (error: AxiosError<ErrorResponse>) => {

        if (error.response?.status === 401) {
            await authService.logout()
        }

        const errorMessage = handleApiError(error);
        console.error(errorMessage);

        return Promise.reject(errorMessage)
    }
)

function handleApiError(error: AxiosError<ErrorResponse>): string {
    if (error.response?.data) {
        const { message, errors } = error.response.data;

        if (errors) {
            const firstErrorKey = Object.keys(errors)[0];
            if (firstErrorKey && Array.isArray(errors[firstErrorKey])) {
                return errors[firstErrorKey][0];
            }
        }

        if (message) {
            return message;
        }
    }

    return error.message || "An unexpected error occurred";
}

export default apiClient